/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetIntegration } from '@src/hooks/queries/integrations/integrations';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { TID } from '@src/types/common';

import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';

import styles from './styles.module.scss';

interface RevenueCaptureHeaderTableProps {
  integrationId: TID | undefined | null;
  revenueSystemName: string;
}
const maxLength = 46;
const charsInterval = 3;
const currentFont = 18;
const maxReducingFont = 5;
const getFontSize = (headerTxt: string | number | null | undefined) => {
  const headerLength = `${headerTxt}`.length;
  if (!headerTxt) {
    return currentFont;
  }
  if (headerLength > maxLength) {
    const difference = headerLength - maxLength;
    const reducingFont = difference / charsInterval;
    if (reducingFont > maxReducingFont) {
      return null;
    }
    return currentFont - reducingFont;
  }
  return currentFont;
};
const renderTextWithTooltip = (
  text: string,
  fontSize: number | null,
  email?: boolean,
  emailText?: string,
) => {
  return text.length <= maxLength ? (
    email ? emailText : text
  ) : fontSize == null ? (
    <>
      {email ? `${emailText}`.substring(0, (text.length - maxLength)) : text.substring(0, maxLength)}
      <Text as="h2" className={ email ? 'in-blue-900' : '' } inColor="black">...</Text>
    </>
  ) : (
    <Text as="h2" className={ styles[`header-font-${fontSize}`] }>{email ? emailText : text}</Text>
  );
};
const RevenueCaptureHeaderTable = ({
  integrationId,
  revenueSystemName,
}: RevenueCaptureHeaderTableProps) => {
  const business = useBusinessContext();
  const fontSizeRevenueSystemName = getFontSize(revenueSystemName);
  const revenueCaptureHeaderFeatureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CAPTURE_NEW_HEADERS,
  );

  const integration = useGetIntegration(
    { integrationId, businessId: business.id },
    { enabled: !!integrationId },
  );
  const [showTooltip, setShowTooltip] = useState(false);
  const handleCopyEmail = useCallback(() => {
    setShowTooltip(true);
    setTimeout(() => {
      navigator.clipboard.writeText(`${integration?.data?.emailAddress}`);
      setShowTooltip(false);
    }, 1500);
  }, [integration?.data?.emailAddress]);

  if (!revenueCaptureHeaderFeatureQuery.data) return null;
  const getHeaderPullText = () => {
    if (!integration.data?.externalSystemConfig?.location) {
      return revenueSystemName;
    }
    if (!Number.isNaN(Number(integration.data?.externalSystemConfig?.location))) {
      return `Property ID/ Store ID : ${integration.data?.externalSystemConfig?.location}`;
    }
    return integration.data?.externalSystemConfig?.location;
  };
  const headerPullText = getHeaderPullText();
  const fontSizeLocation = getFontSize(headerPullText);
  const fontSizeEmail = getFontSize(`${revenueSystemName}${integration?.data?.emailAddress}`);
  return (
    <>
      {!integration?.data && (
        <Text as="h2">
          <Tooltip.Hover
            content={ revenueSystemName }
            style={ { cursor: 'pointer' } }
          >
            {renderTextWithTooltip(revenueSystemName, fontSizeRevenueSystemName)}
          </Tooltip.Hover>
        </Text>
      )}
      {integration.data?.integrationType === 'pull' && (
        <Text as="h2">
          <Tooltip.Hover
            content={ headerPullText }
            style={ { cursor: 'pointer' } }
          >
            {renderTextWithTooltip(headerPullText.toString(), fontSizeLocation)}
          </Tooltip.Hover>
        </Text>
      )}
      {integration.data?.integrationType === 'email' && (
        <>
          <Text as="h2">
            <Tooltip.Hover
              content={ revenueSystemName }
              style={ { cursor: 'pointer' } }
            >
              <Text as="h2" className={ `header-font-${fontSizeEmail}` }>
                {revenueSystemName}
              </Text>
            </Tooltip.Hover>
          </Text>
          <div className={ styles['vertical-divider'] } />
          <Text className="in-blue-900">
            <Tooltip.Hover content={ integration?.data?.emailAddress }>
              <Tooltip.Hover
                isControl
                content="Email copied to clipboard"
                showTooltip={ showTooltip }
              >
                <Button className={ styles['without-gap'] } variant="link" onClick={ handleCopyEmail }>
                  {renderTextWithTooltip(`${revenueSystemName}${integration?.data?.emailAddress}`, fontSizeEmail, true, `${integration?.data?.emailAddress}`)}
                </Button>
              </Tooltip.Hover>
            </Tooltip.Hover>
          </Text>
        </>
      )}
    </>
  );
};
export default React.memo(RevenueCaptureHeaderTable);
