import React, { useMemo } from 'react';

import moment from 'moment';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { FileTextIcon } from '@src/components/utils/fa_icons';
import { ChatIcon } from '@src/components/utils/icomoon';

type TInvoiceQueueListActionsProps = {
  accountsPayableServiceDocument: IAccountsPayableServiceDocument;
};

const InvoiceQueueListActions = ({ accountsPayableServiceDocument }: TInvoiceQueueListActionsProps) => {
  const business = useBusinessContext();
  const beforeBookDate = useMemo(
    () => moment(accountsPayableServiceDocument.invoiceDate) < moment(business.reconciliationStartDate),
    [accountsPayableServiceDocument, business]
  );


  return (
    <>
      <ActionsDropdown.LinkAction
        disabled={ beforeBookDate }
        href={ `/document/${accountsPayableServiceDocument.documentId}/chat` }
        icon={ <ChatIcon fontSize={ 20 } variant="round" /> }
        title="Send a Message"
      />
      <ActionsDropdown.LinkAction
        disabled={ beforeBookDate }
        href={ `/document/${accountsPayableServiceDocument.documentId}` }
        icon={ <FileTextIcon fontSize={ 20 } variant="o" /> }
        title="View Detail"
      />
    </>
  );
};

export default InvoiceQueueListActions;
