import React, { useEffect, useState } from 'react';

import { IReport } from '@src/types/report_service/report';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';
import { IReportData } from '@src/types/report_service/report_data';
import { IReportItem } from '@src/types/report_service/report_item';

import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import Banners from './banners';
import Table from './table';
import { useReportDatasCollection } from '../tableHooks';

import styles from '../styles.module.scss';

interface IListProps {
  report: IReport;
  items: IReportItem[];
  configurations: IReportConfiguration[];
}

const List = ({ report, items, configurations }: IListProps): JSX.Element => {
  const [update, setUpdate] = useState<boolean>(false);
  const collection = useReportDatasCollection({report});

  useEffect(() => {
    const updatingDatas = collection?.records?.filter((reportData) => (
      reportData.updateState
      === window.Docyt.Common.Constants.CUSTOM_REPORT_STATE.UPDATE_STATE_QUEUED
      || reportData.updateState
      === window.Docyt.Common.Constants.CUSTOM_REPORT_STATE.UPDATE_STATE_STARTED
    ));
    setUpdate(updatingDatas?.length !== 0);
  },[collection.records, setUpdate]);

  return (
    <Section.Provider section={ collection.section }>
      <Banners
        report={ report }
        reportDatas={ collection.records as IReportData[] }
        update={ update }
      />
      <TableSection className={ styles['report-data-table-section'] }>
        <Table 
          collection={ collection }
          configurations={ configurations }
          items={ items }
          report={ report }
          setUpdate={ setUpdate }
          update={ update }
        />
      </TableSection>
    </Section.Provider>
  );
};

export default List;
