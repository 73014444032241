import React, { useCallback } from 'react';

import { useUpdateStarredFlag } from '@src/hooks/queries/documents';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { StarIcon } from '@src/components/utils/fa_icons';

interface IInvoiceStarFlagProps {
  accountsPayableServiceDocument: IAccountsPayableServiceDocument,
}

const InvoiceStarFlag = ({ accountsPayableServiceDocument }: IInvoiceStarFlagProps) => {
  const currentUserId = window.Docyt.currentAdvisor.get('id');
  const update = useUpdateStarredFlag();
  const { mutate } = update;

  const handleStarFlagClick = useCallback(() => {
    mutate(
      {
        id: accountsPayableServiceDocument.documentId,
        starredFlag: !accountsPayableServiceDocument.starredById,
      },
      {
        onSuccess: () => {
          accountsPayableServiceDocument.starredById = accountsPayableServiceDocument.starredById ? null : currentUserId;
        },
      }
    );
  }, [currentUserId, accountsPayableServiceDocument, mutate]);

  return (
    <StarIcon
      fontSize={ 18 }
      type={ accountsPayableServiceDocument.starredById ? 'yellow' : 'outline' }
      onClick={ handleStarFlagClick }
    />
  );
};

export default React.memo(InvoiceStarFlag);
