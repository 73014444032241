import React, { useState } from 'react';

import { useAccountsPayableServiceContext } from '@src/hooks/contexts/accounts_payable_service_context';
import { useDebouncedCallback } from '@src/hooks/utils';
import { getRemainingApproverNames } from '@src/requests/accounts_payable/accounts_payable_service_documents';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import Tooltip from '@src/components/ui_v2/tooltip';

import { getInvoiceStatus, getInvoiceStatusClass } from '../utils';

interface IInvoiceStatusProps {
  accountsPayableServiceDocument: IAccountsPayableServiceDocument;
}

const InvoiceStatus = ({ accountsPayableServiceDocument }: IInvoiceStatusProps) => {
  const currentUserId = window.Docyt.currentAdvisor.get('id');
  const service = useAccountsPayableServiceContext();
  const [approverNames, setApproverNames] = useState<string | undefined>(undefined);

  const handleMouseOver = useDebouncedCallback(
    () => {
      if (accountsPayableServiceDocument.state === window.configData.ap_invoice_states.DOCUMENT_STATE_APPROVAL) {
        getRemainingApproverNames(accountsPayableServiceDocument.id).then(res => {
          setApproverNames(`Needs Approval: ${res.approverNames}`);
        });
      }
    },
    [],
    300
  );

  const status = getInvoiceStatus(accountsPayableServiceDocument, currentUserId, service.businessServiceRole);
  if (!status) return null;

  return (
    <Tooltip.Hover content={ approverNames || accountsPayableServiceDocument.paymentTransactionError }>
      <div onFocus={ handleMouseOver } onMouseOver={ handleMouseOver }>
        <span className={ `invoice-status-panel font-bold text-nowrap ${getInvoiceStatusClass(accountsPayableServiceDocument, status)}` }>
          { status }
        </span>
      </div>
    </Tooltip.Hover>
  );
};

export default React.memo(InvoiceStatus);
