import React from 'react';

import pluralize from 'pluralize';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { Button } from '@src/components/ui_v2/buttons';

interface IChartOfAccountSplitsViewProps {
  accountsPayableServiceDocument: IAccountsPayableServiceDocument;
  onEdit: () => void;
}

const ChartOfAccountSplitsView = ({ accountsPayableServiceDocument, onEdit }: IChartOfAccountSplitsViewProps) => {
  const isCategoryReadonly = accountsPayableServiceDocument.recurringBillId !== null;

  const serviceDocumentSplits = accountsPayableServiceDocument.serviceDocumentSplits || [];
  const serviceDocumentSplitsCount = accountsPayableServiceDocument.serviceDocumentSplitsCount || serviceDocumentSplits.length;

  const serviceLineItemsCount = accountsPayableServiceDocument.serviceDocumentLineItems?.length || 0;
  const filedValue = accountsPayableServiceDocument.chartOfAccount?.displayName;

  let predictedChartOfAccountLineItem;
  if (serviceDocumentSplitsCount === 1 && accountsPayableServiceDocument.serviceDocumentLineItems) {
    predictedChartOfAccountLineItem = accountsPayableServiceDocument.serviceDocumentLineItems[0]?.predictedChartOfAccountName;
  }

  const selectCategory = isCategoryReadonly ? 'View Category' : 'Select Category';
  const value = predictedChartOfAccountLineItem || filedValue;
  const count = serviceDocumentSplitsCount === 0 ? serviceLineItemsCount : serviceDocumentSplitsCount;

  if (count === 0) {
    return (
      <div>
        { value ? (
          <>
            { value }
            <br />
            <Button variant="link" onClick={ onEdit }>
              Edit
            </Button>
          </>
        ) : (
          <Button variant="link" onClick={ onEdit }>
            { selectCategory }
          </Button>
        ) }
      </div>
    );
  }

  return (
    <Button variant="link" onClick={ onEdit }>
      { `${count} ${pluralize('Split', count)}` }
    </Button>
  );
};

export default React.memo(ChartOfAccountSplitsView);
